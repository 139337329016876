// HeroSection.js
import React from 'react';
import ReadyToInspectSection from "./ReadyToInspectSection";
import SubscribeBtnSection from "./SubscribeBtnSection";
import VideoSection4 from "./VideoSection4";
const AboutPage = () => (
    <div className={"content"}>
        <h1>How File Baby Works</h1>
        <p>File Baby is designed to help you peek inside files and know more about them than meets the eye. Built as a practical way to implement technology developed by The Coalition for Content Provenance and Authenticity (C2PA) and the Content Authenticity Initiative (CAI), File Baby offers a simple drag-and-drop interface along with a cheerful AI assistant that is expert in file provenance.</p><p>Files that you share that have been claimed using File Baby are no longer anonymous; instead, if someone wants to pay for your work, they can inspect using the content credentials verifier, helping someone to quickly and easily see claims of a file's origin. In the animation below, the <a href={"https://contentcredentials.org/verify"}>Content Credentials</a> verifier is being used to inspect an original photograph that was claimed in File Baby.</p>
        <div className={"centeredImageFull"}><div className={"readytoinspect"}><ReadyToInspectSection /></div></div>

        <p>When you sign in to File Baby, the first thing you will notice is its simple, straightforward interface. File Baby's features offer a smorgasboard of abilities that support all users of content credentials, from beginner to advanced. </p>
        <p>Exclusive to File Baby, you can use its simple interface to generate a custom manifest and then claim a file using that manifest. You create your manifest by answering a few simple questions and then File Baby walks you through uploading your custom manifest with the file you want to claim. After you have claimed your file, you can decide whether to drag your file to local storage or save it to File Baby, or both.</p>
        <p>Saving your claimed file to File Baby opens up another world of possibilities with additional features that allow you to store and share your files with others and to create and share ad-hoc galleries with people who don't have File Baby. Files stored in File Baby offer an easy integration with the Content Credentials verifier so you can view the official low-down on your files with one simple click.</p>
            <p>This video shows the Love More Records hip hop recording artist BAANG claiming his new release on File Baby.</p>
            <VideoSection4 />
            <p>File Baby also helps you do a deep-dive into any file that has been claimed because it lets you inspect the manifests, and File Baby's AI assistant can give you a detailed analysis of the manifests.</p>
        <p>Is GenAI your thing? File Baby has an onboard AI Image Generator that creates files automatically signed by Microsoft Responsible AI.</p>
        <p>Don't miss out on knowing what goes into the files you see every day and claiming what's yours–Subscribe to File Baby now.</p>

            <SubscribeBtnSection />
    </div>
);


export default AboutPage;