// HeroSection.js
import React from 'react';
import SubscribeBtnSection from "./SubscribeBtnSection";

const MerchPage = () => (
    <div className="content">
        <h1>Merch</h1>
        <p>Available by popular demand, you can now tell the world "That's My File, Baby!" by rocking File Baby merch! We have File Baby Blue t-shirts, hats, PJs, dogtags, and more.</p>
        <p>To see the selection, visit <a href={"https://www.cafepress.com/filebaby"}>File Baby's Store on Cafe Press</a>, where you will find something for every File Baby fan in your life.</p>
        <SubscribeBtnSection />
    </div>
);


export default MerchPage;