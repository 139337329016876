// FileBabyLargeLogoSection.js
import React from 'react';
import LargeLogo from "./logo_myfilebaby.png";

const FileBabyLargeLogoSection = () => (
    <div className={"filebabylarge"}>
        <img src={LargeLogo} alt={"File Baby"} />
    </div>
);


export default FileBabyLargeLogoSection;



