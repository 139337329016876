// VideoPage.js
import React from 'react';
import SubscribeBtnSection from "./SubscribeBtnSection";
import VideoSection from "./VideoSection";
import VideoSection2 from "./VideoSection2";
import VideoSection3 from "./VideoSection3";

const VideoPage = () => (
    <div className="content">
        <h1>Videos</h1>
        <div></div>
        <VideoSection />
        <VideoSection2 />
        <VideoSection3 />
        <SubscribeBtnSection />
    </div>
);


export default VideoPage;