// Footer.js
import React from 'react';
import FileBabyTop from "./file_baby_logo_top.png";
const Footer = () => (
    <section className="footer">
        <div className={"footer"}>
            <div>
                <img className="filebabyfooter" src={FileBabyTop} alt={"File Baby logo"} />
            </div>
            <p>
                &copy;Copyright 2024, <a href="https://my.file.baby">File Baby</a>, All Rights Reserved
            </p>

        </div>
    </section>
);

export default Footer;


