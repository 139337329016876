//blogPosts/blogPosts.js
import image1 from '../kbc_logo_1200.png';

const blogPosts = [
    {
        id: 1,
        urlkey:'filebaby',
        title: 'File Baby Partners with Kilroy Blockchain to Add Enterprise Security',
        author: 'Karen Kilroy',
        date: 'February 28, 2024',
        image: image1,
        alt: 'That\'s My File, BABY!',
        description: 'File Baby is partnering with Kilroy Blockchain to offer enterprise-level blockchain security to its Content Authenticity and Provenance Platform.',
        component: () => import('./BlogPost1'), // Assuming BlogPost1.js returns a default React component
    },
];

export default blogPosts;